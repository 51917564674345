import { useDsNavigationLoaderStore } from "@devsalsa/vue-core";

import AccountRoutes from "@/core/modules/account/router";
import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";

import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

import i18n from "@/i18n";
import CampaignRoutes from "@/modules/campaign/router";
import ChatRoutes from "@/modules/chat/router";
import JobRoutes from "@/modules/job/router";
import JobsRoutes from "@/modules/jobs/router";
import MarketplaceRoutes from "@/modules/marketplace/router";
import OnboardingRoutes from "@/modules/onboarding/router";
import PortfolioRoutes from "@/modules/portfolio/router";
import RecurringJobRoutes from "@/modules/recurring-job/router";
import RecurringJobsRoutes from "@/modules/recurring-jobs/router";
import SettingsRoutes from "@/modules/settings/router";
import PageNotFoundRoutes from "@/shared/modules/page-not-found/router";

const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        beforeEnter: OnboardingGuard.withCompleteOnboardingAndNoJobs,
        meta: { title: "common.route.dashboard.meta" },
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: () =>
          import("@/modules/notification/views/TheNotifications.vue"),
        beforeEnter: OnboardingGuard.withCompleteOnboarding,
        meta: { title: "common.route.notifications.meta" },
      },
    ],
  },
];

const routes: Array<RouteRecordRaw> = baseRoutes.concat(
  AccountRoutes,
  PageNotFoundRoutes,
  OnboardingRoutes,
  JobRoutes,
  JobsRoutes,
  CampaignRoutes,
  ChatRoutes,
  PortfolioRoutes,
  SettingsRoutes,
  MarketplaceRoutes,
  RecurringJobRoutes,
  RecurringJobsRoutes
);

const preloader = window.document.getElementById("preloader") as HTMLElement;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    }
    if (to.name === from.name && to.hash !== "" && to.hash !== from.hash) {
      //Avoid to scroll if we are clicking on tabs.
      return;
    }
    return new Promise((resolve) => {
      //The transition lasts 200ms.
      setTimeout(() => {
        //Manual way to scroll top to our custom container (https://github.com/vuejs/vue-router/issues/1187)
        //It could also be done in the afterEach navigation guard.
        document.getElementById("layout-content")?.scroll({
          top: 0,
          behavior: "smooth",
        });

        resolve({
          top: 0,
          behavior: "smooth",
          //This should be the correct way to do it according to the documentation,
          //but it doesn't work when the scroll is on a custom container (#layout-content in our case).
          //el: "#layout-content",
        });
      }, 200);
    });
  },
});
router.onError((error: unknown) => {
  console.log("VueJS router error handler", error);
  ErrorHandler.handle(error);
});
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (preloader.classList.contains("hidden")) {
      useDsNavigationLoaderStore().enable();
    }
    window.document.title = `${
      import.meta.env.VITE_APP_TITLE
    } - ${i18n.global.t(<string>to.meta.title)}`;
    next();
  }
);
router.afterEach(() => {
  //Hide the preloader if not hidden yet.
  preloader.classList.add("hidden");
  useDsNavigationLoaderStore().disable();
});

export default router;
