<template>
  <div class="sidebar-settings">
    <aside class="sidebar-settings-aside">
      <div class="sidebar-settings-container">
        <div class="sidebar-settings-avatar group">
          <div
            v-if="portfolioPublished"
            class="sidebar-settings-avatar-link bg-gray-900 bg-opacity-0 group-hover:bg-opacity-50"
          >
            <DsExternalLink
              :href="portfolioUrl"
              target="_blank"
              class="hidden group-hover:block"
            >
              <DsExternalLinkSolidIcon class="avatar-link-icon" />
            </DsExternalLink>
          </div>
          <DsAvatar
            :alt="fullName"
            :src="profileImage"
            class="mb-3 z-0"
            :populating="populating"
          />
        </div>
        <div class="sidebar-settings-creator-details">
          <div v-if="!populating" class="creator-name">
            {{ fullName }}
          </div>
          <DsSkeleton v-else class="w-full h-6" />
          <div v-if="!populating" class="creator-date">
            {{ memberSinceText }}
          </div>
          <DsSkeleton v-else class="w-3/4 h-5 mt-1" />
          <div class="creator-rank">
            <DsBadge
              v-if="!populating"
              id="sidebar-creator-level"
              :title="
                $t(`modules.settings.sidebarSettings.rating.level.${level}`)
              "
              theme-color="gray"
              size="sm"
              class="rounded-md"
            />
            <DsSkeleton v-else class="w-40 h-5" />
            <Translation
              v-if="!populating"
              keypath="modules.settings.sidebarSettings.activeJobs"
              tag="p"
              class="text-sm font-normal text-gray-500 pt-1"
            >
              <span class="font-medium text-gray-900">
                {{ activeJobs }}
              </span>
              <span class="font-medium text-gray-900">
                {{ maxJobs }}
              </span>
            </Translation>
            <DsSkeleton v-else class="w-44 h-5 mt-1" />
            <div class="flex items-center pt-1.5">
              <DsRatingStars
                v-if="!populating"
                id="sidebar-rating"
                :rating="rating"
                size="sm"
              />
              <DsSkeleton v-else class="w-24 h-5" />
              <span
                v-if="!populating"
                class="text-sm font-medium text-gray-900 ml-2"
              >
                {{ formattedRating }}
              </span>
              <DsSkeleton v-else class="w-6 h-5 ml-1" />
              <span
                v-if="!populating"
                class="text-sm font-normal text-gray-500 ml-1.5"
              >
                {{ ratingsText }}
              </span>
              <DsSkeleton v-else class="w-6 h-5 ml-1" />
            </div>
            <DsButton
              theme-color="blue"
              theme-display="link"
              class="ds-link-no-underline pt-3 text-sm font-normal"
              data-testid="modal-link-levels"
              @click="onOpenModal"
            >
              {{ $t(`modules.settings.sidebarSettings.linkLevels`) }}
            </DsButton>
          </div>
        </div>
        <div class="sidebar-settings-links">
          <nav>
            <SidebarSettingsItem
              :to="{ name: 'Account' }"
              route-name="Account"
              icon="DsUserSolidIcon"
              :title="$t('modules.settings.account.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'PortfolioSummary' }"
              route-name="PortfolioSummary"
              icon="DsPortfolioSolidIcon"
              :title="$t('modules.settings.portfolio.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Reviews' }"
              route-name="Reviews"
              icon="DsStarSolidIcon"
              :title="$t('modules.settings.reviews.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Wallet' }"
              route-name="Wallet"
              icon="DsMoneySolidIcon"
              :title="$t('modules.settings.wallet.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'PayoutMethod' }"
              route-name="PayoutMethod"
              icon="DsCardSolidIcon"
              :title="$t('modules.settings.payoutMethod.sidebarText')"
            />
            <SidebarSettingsItem
              :to="{ name: 'Certifications' }"
              route-name="Certifications"
              icon="DsBadgeSolidIcon"
              :title="$t('modules.settings.certifications.sidebarText')"
            >
              <template #title>
                <Translation
                  keypath="modules.settings.certifications.sidebarText"
                  tag="p"
                  class="text-sm group-hover:text-jb-gray-900"
                >
                  <span class="text-sm font-medium text-gray-900">
                    {{ certificates.approved }}
                  </span>
                  <span class="text-sm font-medium text-gray-900">
                    {{ certificates.total }}
                  </span>
                </Translation>
              </template>
            </SidebarSettingsItem>
            <SidebarSettingsItem
              :to="{ name: 'NotificationSettings' }"
              route-name="NotificationSettings"
              icon="DsBellSolidIcon"
              :title="$t('modules.settings.notifications.sidebarText')"
            />

            <SidebarSettingsItem
              v-if="false"
              :to="{ name: 'RequestAFeature' }"
              route-name="RequestAFeature"
              icon="DsBellSolidIcon"
              :title="$t('modules.settings.requestFeature.sidebarText')"
            />
            <DsButton
              theme-display="link"
              class="contact-link ds-link-no-underline group"
              data-testid="contact-support-sidebar-link"
              @click="onOpenContactModal"
            >
              <DsContactSolidIcon
                class="contact-link-icon text-jb-gray-400 group-hover:text-jb-gray-900"
              />
              <span
                class="contact-link-text text-gray-500 group-hover:text-jb-gray-900"
              >
                {{ $t(`modules.settings.contact.sidebarText`) }}
              </span>
            </DsButton>
            <SidebarSettingsItem
              :to="{ name: 'AffiliateProgram' }"
              route-name="AffiliateProgram"
              icon="DsTicketSolidIcon"
              :title="$t('modules.settings.affiliate.sidebarText')"
            />
          </nav>
        </div>
      </div>
    </aside>
    <CreatorLevelsModal
      id="creator-levels-sidebar-modal"
      ref="creatorLevelSidebarRef"
      :title="$t('modules.settings.portfolio.creatorLevels.modal.title')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import {
  DsAvatar,
  DsBadge,
  DsButton,
  DsContactSolidIcon,
  DsExternalLink,
  DsExternalLinkSolidIcon,
  DsModalHandler,
  DsRatingStars,
  DsSkeleton,
  useDsDatetime,
} from "@devsalsa/vue-core";

import AccountService from "@/core/modules/account/services/AccountService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import { Translation, useI18n } from "vue-i18n";

import CreatorLevelsModal from "@/modules/settings/components/account/CreatorLevelsModal.vue";
import SidebarSettingsItem from "@/modules/settings/components/SidebarSettingsItem.vue";

defineOptions({
  name: "SidebarSettings",
});

const { t } = useI18n();
const { formatUnixTime } = useDsDatetime();

const populating = ref(true);
const activeJobs = ref(0);
const certificates = ref({
  approved: 0,
  total: 0,
});
const creatorLevelSidebarRef = ref<InstanceType<
  typeof CreatorLevelsModal
> | null>(null);

const level = computed((): number => {
  return AccountHandler.accountInfo.value?.level || 1;
});

const fullName = computed((): string => {
  return `${AccountHandler.accountInfo.value?.first_name || ""} ${
    AccountHandler.accountInfo.value?.last_name || ""
  }`;
});

const profileImage = computed((): string => {
  return AccountHandler.accountInfo.value?.profile_image || "";
});

const rating = computed((): number => {
  return AccountHandler.accountInfo.value?.rating.account.avg_stars || 0;
});

const ratings = computed((): number => {
  return AccountHandler.accountInfo.value?.rating.account.ratings || 0;
});

const formattedRating = computed((): string => {
  return (
    AccountHandler.accountInfo.value?.rating.account.avg_stars.toFixed(1) || ""
  );
});

const maxJobs = computed((): number => {
  return AccountHandler.accountInfo.value?.max_jobs || 0;
});

const ratingsText = computed((): string => {
  return t("modules.settings.sidebarSettings.ratings", {
    total: ratings.value,
  });
});

const signUpDate = computed((): string => {
  return formatUnixTime(
    AccountHandler.accountInfo.value?.date_created || 0,
    "short"
  );
});

const memberSinceText = computed((): string => {
  return t("modules.settings.sidebarSettings.memberSince", {
    signUpDate: signUpDate.value,
  });
});

const portfolioPublished = computed((): boolean => {
  return AccountHandler.accountInfo.value?.profile_online || false;
});

const portfolioUrl = computed((): string => {
  return `${import.meta.env.VITE_APP_WEBSITE_URL}/@${
    AccountHandler.accountInfo.value?.profile_handle
  }`;
});

onMounted(() => {
  populateAccount();
});

async function populateAccount(): Promise<void> {
  populating.value = true;
  const creatorStats = await AccountService.getStats();
  activeJobs.value = creatorStats.active_jobs;
  certificates.value.approved = creatorStats.nr_certification_approved;
  certificates.value.total = creatorStats.nr_certifications;
  populating.value = false;
}

function onOpenModal() {
  (
    creatorLevelSidebarRef.value as InstanceType<typeof CreatorLevelsModal>
  ).open();
}

function onOpenContactModal() {
  DsModalHandler.open("contact-support-modal");
}
</script>

<style lang="scss" scoped>
.sidebar-settings {
  @apply h-full;

  &-aside {
    @apply xl:flex flex-col w-full xl:w-[264px] h-full xl:p-6 flex-shrink-0 z-0 xl:sticky top-0 xl:max-h-[calc(100vh-64px)] xl:h-auto;

    .sidebar-settings-container {
      @apply flex flex-col flex-grow overflow-y-auto;

      .sidebar-settings-creator-details {
        @apply flex flex-col pb-6 mb-6 border-gray-200 border-b mt-3;

        .creator-name {
          @apply text-base font-semibold text-gray-900;
        }

        .creator-date {
          @apply text-sm font-normal text-gray-500;
        }

        .creator-rank {
          @apply whitespace-nowrap overflow-hidden text-ellipsis pt-3;
        }
      }

      .sidebar-settings-links {
        @apply flex-grow flex flex-col;

        nav {
          @apply flex-1 space-y-4 pb-1;

          .contact-link {
            @apply hover:no-underline focus:no-underline  w-full text-left;

            .contact-link-icon {
              @apply h-5 w-5 inline-block mr-4;
            }

            .contact-link-text {
              @apply text-sm font-medium;
            }
          }
        }
      }
    }

    .sidebar-settings-avatar {
      @apply relative w-12 h-12 rounded-full;

      &-link {
        @apply absolute inset-0 z-10 flex items-center justify-center rounded-full;

        .avatar-link-icon {
          @apply h-6 w-6 fill-white;
        }
      }
    }
  }
}
</style>
