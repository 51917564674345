import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/job/",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        redirect: { name: "Jobs" },
      },
      {
        path: ":jobId(\\d+)/delivery-address",
        name: "DeliveryAddress",
        component: () => import("@/modules/job/views/DeliveryAddress.vue"),
        meta: {
          title: "modules.job.deliveryAddress.meta",
        },
      },
      {
        path: ":jobId(\\d+)/purchase-instructions",
        name: "PurchaseInstructions",
        component: () => import("@/modules/job/views/PurchaseInstructions.vue"),
        meta: {
          title: "modules.job.purchaseInstructions.meta",
        },
      },
      {
        path: ":jobId(\\d+)",
        name: "SummaryJob",
        component: () => import("@/modules/job/views/JobSummary.vue"),
        meta: {
          title: "modules.job.summaryJob.meta",
        },
      },
    ],
  },
];
