import { useWebSocketStore } from "@/store/WebSocketStore";

import type { WebSocketOptions } from "@/core/shared/plugins/WebSocket/WebSocketClient.types";

import Emitter from "./Emitter";
import Observer from "./Observer";
import type { App } from "vue";

export default {
  install(app: App, url: string, opts: WebSocketOptions = {}): void {
    //No web socket URL provided, throw an exception.
    if (!url) {
      throw new Error("The web socket URL is required");
    }

    opts.$setInstance = (webSocket: WebSocket) => {
      // Add $socket to global properties
      useWebSocketStore().set$Socket(webSocket);
    };

    // Automatically connect to the web socket
    const observer = new Observer(url, opts);

    // Add the $socket attribute globally to connect to the websocket server
    useWebSocketStore().set$Socket(observer.webSocket as WebSocket);

    const hasProxy =
      typeof Proxy !== "undefined" &&
      typeof Proxy === "function" &&
      /native code/.test(Proxy.toString());

    app.mixin({
      created() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this;
        const sockets = this.$options["sockets"];

        if (hasProxy) {
          this.$options.sockets = new Proxy(
            {},
            {
              // eslint-disable-next-line
              set(target: any, key: any, value: any): boolean {
                // Add monitor
                Emitter.addListener(key, value, vm);
                target[key] = value;
                return true;
              },
              // eslint-disable-next-line
              deleteProperty(target: { key: any }, key: any): boolean {
                // Remove monitor
                Emitter.removeListener(key, vm.$options.sockets[key], vm);
                delete target.key;
                return true;
              },
            }
          );
          app.config.globalProperties.sockets = new Proxy(
            {},
            {
              // eslint-disable-next-line
              set(target: any, key: any, value: any): boolean {
                // Add monitor
                Emitter.addListener(key, value, vm);
                target[key] = value;
                return true;
              },
              // eslint-disable-next-line
              deleteProperty(target: { key: any }, key: any): boolean {
                // Remove monitor
                Emitter.removeListener(key, vm.$options.sockets[key], vm);
                delete target.key;
                return true;
              },
            }
          );
          if (sockets) {
            Object.keys(sockets).forEach((key: string) => {
              // Add the key in sockets to $options
              this.$options.sockets[key] = sockets[key];
              app.config.globalProperties.sockets[key] = sockets[key];
            });
          }
        } else {
          // Seal the object so that it cannot be changed
          Object.seal(this.$options.sockets);
          Object.seal(app.config.globalProperties.sockets);
          if (sockets) {
            Object.keys(sockets).forEach((key: string) => {
              // Add monitor
              Emitter.addListener(key, sockets[key], vm);
            });
          }
        }
      },
      beforeUnmount() {
        if (hasProxy) {
          const sockets = this.$options["sockets"];

          if (sockets) {
            Object.keys(sockets).forEach((key: string) => {
              // If the proxy has sockets before destruction, remove the keys added to sockets in $options
              delete this.$options.sockets[key];
              delete app.config.globalProperties.sockets;
            });
          }
        }
      },
    });
  },
};
