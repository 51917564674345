<template>
  <RouterLink
    v-if="link && to"
    :to="to"
    :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    class="nav-link"
  >
    <slot name="navbar-item"></slot>
    <component
      :is="icon"
      v-if="icon"
      :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    />
    <span v-else>
      {{ text }}
    </span>
  </RouterLink>
  <button
    v-else
    class="nav-link"
    :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
  >
    <slot name="navbar-item"></slot>
    <component
      :is="icon"
      v-if="icon"
      :class="[$route.name === routeName ? 'text-gray-900' : 'text-gray-500']"
    />
    <span v-else>
      {{ text }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import {
  DsBellSolidIcon,
  DsChatBubbleIcon,
  DsDotSolidIcon,
} from "@devsalsa/vue-core";

import type { RouteLocationRaw } from "vue-router";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "NavbarItem",
  components: {
    RouterLink,
    DsChatBubbleIcon,
    DsBellSolidIcon,
    DsDotSolidIcon,
  },
  props: {
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
    to: {
      type: Object as PropType<RouteLocationRaw>,
      required: false,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-link {
  @apply no-underline
  transition ease-in-out duration-200
  cursor-pointer font-medium text-sm
  hover:text-gray-900;
}
</style>
