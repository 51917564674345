import type {
  DsLiveNotification,
  DsLiveNotificationToast,
} from "@devsalsa/vue-core";

import type { RouteLocationRaw } from "vue-router";

export default class NotificationRouterLink {
  static readonly DASHBOARD = "dashboard";
  static readonly JOB_DETAILS = "job_details";
  static readonly JOB_CONTENT = "job_content";
  static readonly JOB_PUBLISH_VIDEO = "job_publish_video";
  static readonly CAMPAIGN = "campaign";
  static readonly CERTIFICATION_REJECTED = "certification_rejected";
  static readonly CERTIFICATIONS = "certifications";
  static readonly BULK_INVOICE_DOWNLOAD = "bulk_invoice_download";
  static readonly JOB_CHAT = "job_chat";
  static readonly ONBOARD = "onboard";
  static readonly REFERRAL = "referral";
  static readonly PAYOUT_METHOD = "payout_method";
  static readonly WALLET = "wallet";
  static readonly PORTFOLIO = "portfolio";
  static readonly PRIVATE_CAMPAIGN = "private_campaign";
  static readonly RECURRENT_CONTRACT_CHAT = "recurrent_contract_chat";

  static get(
    notification: DsLiveNotification | DsLiveNotificationToast
  ): RouteLocationRaw {
    if (notification.action_type === NotificationRouterLink.DASHBOARD) {
      return {
        name: "Dashboard",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_DETAILS) {
      return {
        name: "SummaryJob",
        params: { jobId: notification.action_id },
        hash: "#job-details",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CONTENT) {
      return {
        name: "SummaryJob",
        params: { jobId: notification.action_id },
        hash: "#your-content",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_PUBLISH_VIDEO) {
      return {
        name: "SummaryJob",
        params: { jobId: notification.action_id },
        hash: "#posted-videos",
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CAMPAIGN ||
      notification.action_type === NotificationRouterLink.PRIVATE_CAMPAIGN
    ) {
      return {
        name: "CampaignSummary",
        params: { campaignId: notification.action_id },
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CERTIFICATION_REJECTED
    ) {
      return {
        name: "Certification",
        params: { certificationId: notification.action_id },
      };
    }
    if (
      notification.action_type === NotificationRouterLink.BULK_INVOICE_DOWNLOAD
    ) {
      return notification.action_url;
    }
    if (notification.action_type === NotificationRouterLink.WALLET) {
      return {
        name: "Wallet",
      };
    }
    if (notification.action_type === NotificationRouterLink.CERTIFICATIONS) {
      return {
        name: "Certifications",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CHAT) {
      return {
        name: "SummaryJob",
        params: {
          jobId: notification.action_id,
          hash: "#job-chat",
        },
      };
    }
    if (notification.action_type === NotificationRouterLink.ONBOARD) {
      return {
        name: "PersonalInformation",
      };
    }
    if (notification.action_type === NotificationRouterLink.REFERRAL) {
      return {
        name: "AffiliateProgram",
      };
    }
    if (notification.action_type === NotificationRouterLink.PORTFOLIO) {
      return {
        name: "Portfolio",
      };
    }
    if (notification.action_type === NotificationRouterLink.PAYOUT_METHOD) {
      return {
        name: "PayoutMethod",
      };
    }
    if (
      notification.action_type ===
      NotificationRouterLink.RECURRENT_CONTRACT_CHAT
    ) {
      return {
        name: "TheChat",
        params: { chatId: notification.action_id },
      };
    }
    return { name: "" };
  }
}
