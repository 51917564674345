import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/portfolio",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: "draft",
        name: "PortfolioDraft",
        component: () => import("@/modules/portfolio/views/PortfolioDraft.vue"),
        meta: { title: "common.route.myPortfolio.meta" },
      },
    ],
  },
];
