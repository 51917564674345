import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import RecurringJobGuard from "@/modules/recurring-job/helpers/RecurringJobGuard";

export default [
  {
    path: "/job/recurring",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: ":contractId(\\d+)",
        name: "RecurringJobSummary",
        component: () =>
          import("@/modules/recurring-job/views/ContractSummary.vue"),
        meta: {
          title: "modules.recurringJob.meta",
          cssClasses: ["authenticated-layout-content-white"],
        },
      },
      {
        path: ":contractId(\\d+)/content",
        name: "ContractContent",
        component: () =>
          import("@/modules/recurring-job/views/ContractContent.vue"),
        beforeEnter: RecurringJobGuard.withRecurringJob,
        meta: {
          title: "modules.recurringJob.meta",
          cssClasses: ["authenticated-layout-content-white"],
        },
      },
    ],
  },
];
