<template>
  <div
    :class="[
      'notification-card',
      { 'notification-card-unread': isUnread },
      { 'notification-card-sidebar': isSidebar },
    ]"
    data-testid="notification-card"
    @click="goToAction(notification.id)"
  >
    <DsImageCloud
      class="notification-card-image"
      :src="notification.image_url"
      :alt="notification.title"
    />
    <div :class="['sm:pr-6', { 'sm:pr-0': isSidebar }]">
      <NotificationHeader
        :notification="notification"
        :is-sidebar="isSidebar"
      />
      <NotificationDescription
        :notification="notification"
        :is-sidebar="isSidebar"
      />
    </div>
    <NotificationMenu
      v-if="!isSidebar"
      :notification="notification"
      @mark-read="onMarkRead"
      @delete="onDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";

import { DsImageCloud, type DsLiveNotification } from "@devsalsa/vue-core";

import NotificationDescription from "@/modules/notification/components/NotificationDescription.vue";
import NotificationHeader from "@/modules/notification/components/NotificationHeader.vue";
import NotificationMenu from "@/modules/notification/components/NotificationMenu.vue";

import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

export default defineComponent({
  name: "NotificationCard",
  components: {
    NotificationDescription,
    NotificationHeader,
    NotificationMenu,
    DsImageCloud,
  },
  props: {
    notification: {
      type: Object as PropType<DsLiveNotification>,
      required: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeSidebar", "markRead", "delete"],
  computed: {
    isUnread(): boolean {
      return !(this.notification.date_read && this.notification.date_read > 0);
    },
  },
  methods: {
    goToAction(notificationId: number): void {
      if (this.isSidebar) {
        if (this.notification.date_read === 0) {
          this.$emit("markRead", notificationId);
        }
      }
      this.$emit("closeSidebar");
      this.$router.push(NotificationRouterLink.get(this.notification));
    },
    onMarkRead(id: number) {
      this.$emit("markRead", id);
    },
    onDelete(id: number) {
      this.$emit("delete", id);
    },
  },
});
</script>

<style lang="scss" scoped>
.notification-card {
  @apply p-4 border-b relative sm:p-6 sm:flex hover:bg-gray-50 cursor-pointer;

  &-image {
    @apply object-contain shrink-0 rounded mb-3 w-11 h-11 sm:mb-0 sm:mr-6 sm:w-[72px] sm:h-[72px];
  }
}

.notification-card-unread {
  @apply bg-teal-50;
}

.notification-card-sidebar {
  @apply sm:p-5;
}
</style>
