<template>
  <div class="account-layout">
    <UnauthenticatedNavbar />
    <main>
      <div class="w-full sm:w-3/5 2xl:w-2/5 sm:py-12">
        <div
          class="flex flex-col lg:flex-row sm:rounded-2xl sm:shadow-lg lg:shadow-xl xl:shadow-2xl"
        >
          <div
            class="hidden sm:block lg:w-1/3 bg-cover bg-right-top bg-origin-border rounded-t-none sm:rounded-t-2xl rounded-t-2xl lg:rounded-tr-none lg:rounded-l-2xl w-full h-28 lg:ml-0 lg:h-auto"
            :style="bgProps"
          ></div>
          <div
            class="shadow sm:shadow-none bg-white rounded-2xl m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3"
          >
            <router-view></router-view>
          </div>
        </div>
      </div>
    </main>
    <UnauthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UnauthenticatedFooter from "@/core/shared/components/Layout/UnauthenticatedFooter.vue";
import UnauthenticatedNavbar from "@/core/shared/components/Navigation/UnauthenticatedNavbar.vue";

import backgroundImage from "@/assets/img/backgrounds/account.webp";

export default defineComponent({
  name: "AccountLayout",
  components: { UnauthenticatedFooter, UnauthenticatedNavbar },
  data() {
    return {
      bgProps: {
        backgroundImage: `url(${backgroundImage})`,
      },
    };
  },
});
</script>

<style lang="scss" scoped>
.account-layout {
  main {
    margin-top: 64px;
    min-height: calc(100vh - 192px);
    @apply flex max-h-full justify-center items-center bg-gray-50 lg:bg-white;

    @screen sm {
      min-height: calc(100vh - 128px);
    }
  }
}
</style>
