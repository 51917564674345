import { ApiService } from "@/core/shared/services/ApiService";

import type {
  AffiliateResponse,
  AffiliateStats,
} from "@/modules/settings/services/affiliate/AffiliateService.type";

/**
 * @type AffiliatesSearchFilters
 * @memberOf AffiliateService
 * @property search_phrase {string} Search phrase
 * @property order {number} order
 */
export type AffiliatesSearchFilters = {
  query?: string;
};

/**
 * API Service for the affiliate program
 * @class AffiliateService
 */
export default class AffiliateService {
  /**
   * Get affiliate list of the creator
   * @endpoint /creator/affiliates/payouts/${page}/${rows}
   * @httpMethod POST
   * @throws {BadRequestApiServiceError}
   */
  static list(
    page = 0,
    rows = 25,
    filters: AffiliatesSearchFilters
  ): Promise<AffiliateResponse> {
    return ApiService.post(
      `/creator/affiliates/payouts/${page}/${rows}`,
      filters
    );
  }

  /**
   * Get stats of the affiliates
   * @endpoint /creator/affiliates/stats
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   */
  static getStats(): Promise<AffiliateStats> {
    return ApiService.get("/creator/affiliates/stats");
  }
}
