<template>
  <div class="onboarding-layout">
    <UnauthenticatedNavbar />
    <OnboardingNavbar v-if="navbarActive" class="onboarding-layout-navbar" />
    <main>
      <div class="onboarding-layout-main">
        <div
          class="hidden sm:block sm:col-span-2 lg:col-span-3 2xl:col-span-4"
        />
        <div class="onboarding-layout-content">
          <DsFlash id="social-account" class="mb-10" />
          <DsTip
            v-if="isRejected"
            class="mb-4"
            theme-color="red"
            :title="$t('modules.onboarding.tip.rejected.title')"
            :message="rejectedReason"
          >
            <template #icon>
              <DsAlertSolidIcon />
            </template>
          </DsTip>
          <div class="onboarding-layout-content-shadow">
            <div class="onboarding-layout-content-bigShadow">
              <div class="onboarding-layout-content-view">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
        <div class="onboarding-layout-container-stepper">
          <DsStepper :steps="steps" class="onboarding-layout-stepper" />
        </div>
      </div>
    </main>
    <UnauthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DsAlertSolidIcon,
  DsFlash,
  DsStepper,
  DsTip,
} from "@devsalsa/vue-core";

import UnauthenticatedFooter from "@/core/shared/components/Layout/UnauthenticatedFooter.vue";
import UnauthenticatedNavbar from "@/core/shared/components/Navigation/UnauthenticatedNavbar.vue";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

import OnboardingNavbar from "@/modules/onboarding/components/OnboardingNavbar.vue";

import backgroundImage from "@/assets/img/backgrounds/account.webp";

export default defineComponent({
  name: "OnboardingLayout",
  components: {
    OnboardingNavbar,
    UnauthenticatedFooter,
    UnauthenticatedNavbar,
    DsStepper,
    DsTip,
    DsFlash,
    DsAlertSolidIcon,
  },
  data() {
    return {
      stepperValue: 0,
      approved: true,
      rejectedReason: "",
      bgProps: {
        backgroundImage: `url(${backgroundImage})`,
      },
      steps: [
        {
          name: this.$t("modules.onboarding.personalInformation.step"),
          completed: false,
          active: false,
          routeName: "PersonalInformation",
        },
        {
          name: this.$t("modules.onboarding.aboutMe.step"),
          completed: false,
          active: false,
          routeName: "AboutMe",
        },
        {
          name: this.$t("modules.onboarding.socialNetworks.step"),
          completed: false,
          active: false,
          routeName: "SocialNetworks",
        },
        {
          name: this.$t("modules.onboarding.categories.step"),
          completed: false,
          active: false,
          routeName: "Categories",
        },
      ],
    };
  },
  computed: {
    navbarActive() {
      window.scrollTo(0, 0);
      return this.$route.meta.Navbar;
    },
    isRejected() {
      return !this.approved && this.rejectedReason !== "";
    },
  },
  watch: {
    stepperValue(val: number) {
      this.updateStepper(val);
    },
    $route(to) {
      if (to.meta.stepperPosition > this.stepperValue) {
        this.populateData();
      }
    },
  },
  async created() {
    await this.populateData();
  },
  mounted() {
    this.updateStepper(this.stepperValue as number);
  },
  methods: {
    async populateData() {
      const { onboard_position, approved, rejected_reason } =
        await AccountHandler.getInfo();

      this.stepperValue = onboard_position;
      this.approved = approved;
      this.rejectedReason = rejected_reason;
    },
    updateStepper(val: number) {
      this.steps.map((item, key) => {
        const value: number = val - 1;
        item.completed = value > key;
        item.active = value >= key;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.onboarding-layout {
  &-navbar {
    @apply fixed w-full z-10;
  }

  main {
    @apply flex max-h-full min-h-[calc(100vh-192px)] bg-gray-50 sm:bg-white z-0 relative mt-16 items-start;

    @screen sm {
      @apply min-h-[calc(100vh-128px)];
    }
  }

  &-main {
    @apply grid grid-cols-12 w-full;
  }

  &-content {
    @apply bg-gray-50 sm:bg-white mt-16 lg:mt-20 xl:mt-0 sm:py-8 xl:py-28 w-full col-span-12 sm:col-span-8 lg:col-span-6 2xl:col-span-4;

    &-shadow {
      @apply shadow-lg lg:shadow-none sm:rounded-2xl bg-white;
    }

    &-bigShadow {
      @apply flex flex-col lg:flex-row lg:rounded-2xl lg:shadow-2xl;
    }

    &-view {
      @apply w-full p-4 sm:p-6 md:p-8 xl:p-16;
    }
  }

  &-container-stepper {
    @apply justify-center hidden sm:block sm:col-span-2 lg:col-span-3 pl-10;
  }

  &-stepper {
    @apply mt-28 mx-auto fixed z-10 hidden lg:block;
  }
}
</style>
