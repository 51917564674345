import { createI18n } from "vue-i18n";

import { useMarketplace } from "@/shared/composables/Marketplace";

const { datetimeFormats, numberFormats, messages } = useMarketplace();

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "en-US",
  fallbackLocale: "en-US",
  messages,
  datetimeFormats,
  numberFormats,
});
