import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/jobs/recurring",
    name: "TheRecurringJobs",
    component: () =>
      import("@/modules/recurring-jobs/views/TheRecurringJobs.vue"),
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    meta: {
      title: "common.route.recurringJobs.meta",
      cssClasses: ["authenticated-layout-content-white"],
    },
  },
];
