import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/jobs",
    name: "Jobs",
    component: () => import("@/modules/jobs/views/TheJobs.vue"),
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    meta: { title: "common.route.jobs.meta" },
  },
];
