<template>
  <DsModal
    :id="id"
    :static="false"
    size="lg"
    data-testid="creator-levels-modal"
  >
    <DsModalHeader :title="title" hide-close-icon />
    <DsModalBody>
      <CreatorLevels />
      <Translation
        keypath="modules.settings.portfolio.creatorLevels.modal.footer"
        tag="p"
        class="text-xs text-gray-500 pt-4"
      >
        <DsExternalLink
          href="https://help.joinbrands.com/en/articles/6363126-creators-levels"
          target="_blank"
          theme-color="gray"
          rel="nofollow"
        >
          {{ $t("modules.settings.portfolio.creatorLevels.modal.link") }}
        </DsExternalLink>
      </Translation>
      <div class="cancel-modal-footer">
        <DsButton
          outline
          theme-color="black"
          data-testid="cancel-confirmation-modal"
          size="lg"
          class="cancel-modal-footer-button"
          @click="close"
        >
          {{ $t("common.button.cancel") }}
        </DsButton>
      </div>
    </DsModalBody>
  </DsModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DsButton,
  DsExternalLink,
  DsModal,
  DsModalBody,
  DsModalHandler,
  DsModalHeader,
} from "@devsalsa/vue-core";

import { Translation } from "vue-i18n";

import CreatorLevels from "@/modules/settings/components/account/CreatorLevels.vue";

import type { LevelRequirements } from "@/services/AssetsService.types";

import i18n from "@/i18n";

export default defineComponent({
  name: "CreatorLevelsModal",
  components: {
    DsModal,
    DsModalHeader,
    DsModalBody,
    DsButton,
    DsExternalLink,
    CreatorLevels,
    Translation,
  },
  props: {
    title: {
      type: String,
      default: i18n.global.t(
        "modules.settings.portfolio.creatorLevels.modal.title"
      ),
    },
    id: {
      type: String,
      default: "creator-levels-modal",
    },
  },
  data() {
    return {
      levels: [] as LevelRequirements[],
    };
  },
  methods: {
    close() {
      DsModalHandler.close(this.id);
    },
    open() {
      DsModalHandler.open(this.id);
    },
  },
});
</script>
<style lang="scss" scoped>
.cancel-modal-footer {
  @apply w-full  mt-5 pt-5;
  .cancel-modal-footer-button {
    @apply w-full;
  }
}
</style>
