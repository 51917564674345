<template>
  <router-link
    :to="to"
    class="sidebar-settings-item group"
    :class="[isSelected ? 'text-jb-gray-900' : customClass]"
  >
    <component
      :is="icon"
      class="settings-icon group-hover:text-jb-gray-900"
      :class="[isSelected ? 'text-jb-gray-900' : iconClass]"
    />
    <span
      v-if="!hasSlotTitle"
      class="settings-label group-hover:text-jb-gray-900"
    >
      {{ title }}
    </span>
    <slot v-else :name="idSlot" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DsBadgeSolidIcon,
  DsBellSolidIcon,
  DsCardSolidIcon,
  DsMoneySolidIcon,
  DsPortfolioSolidIcon,
  DsStarSolidIcon,
  DsTicketSolidIcon,
  DsUserSolidIcon,
} from "@devsalsa/vue-core";

export default defineComponent({
  name: "SidebarSettingsItem",
  components: {
    DsUserSolidIcon,
    DsPortfolioSolidIcon,
    DsStarSolidIcon,
    DsMoneySolidIcon,
    DsCardSolidIcon,
    DsBadgeSolidIcon,
    DsBellSolidIcon,
    DsTicketSolidIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "text-gray-400",
    },
    customClass: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      idSlot: "title",
    };
  },
  computed: {
    isSelected(): boolean {
      return this.$route.name === this.routeName;
    },
    hasSlotTitle(): boolean {
      return Reflect.has(this.$slots, this.idSlot);
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-settings-item {
  @apply flex flex items-center text-sm font-medium rounded-md transition-colors duration-100;

  .settings-icon {
    @apply h-5 w-5 block mr-4 shrink-0;
  }

  .settings-label {
    @apply flex-1;
  }
}
</style>
