import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import { ApiErrorCode } from "@/shared/enums/ApiErrorCodeEnum";

import i18n from "@/i18n";

export class ApiErrorTranslator {
  static translate(error: ApiServiceError): string {
    //[500] Errors response
    if (error.code === ApiErrorCode.InternalServerError) {
      return i18n.global.t("common.error.global.internalServerError", {
        id: error.data.event_id,
      });
    }

    //[503] Maintenance response
    if (error.code === ApiErrorCode.Maintenance) {
      return i18n.global.t("common.error.global.maintenance", {
        project: import.meta.env.VITE_APP_TITLE,
      });
    }

    //[503] Upgrading response
    if (error.code === ApiErrorCode.Upgrading) {
      return i18n.global.t("common.error.global.upgrading", {
        project: import.meta.env.VITE_APP_TITLE,
      });
    }

    const key = error.code
      .split("_")
      .map((str, key) =>
        !key ? str : str.charAt(0).toUpperCase() + str.slice(1)
      )
      .join("");

    return i18n.global.t(`common.error.global.${key}`);
  }
}
