<template>
  <div id="authenticated-layout" class="authenticated-layout">
    <AuthenticatedLayoutHeader />
    <div id="layout-content" :class="cssClasses">
      <main class="authenticated-layout-content-main">
        <RouterView v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.name" />
          </transition>
        </RouterView>
      </main>
      <AuthenticatedFooter />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import AuthenticatedFooter from "@/core/shared/components/Layout/AuthenticatedFooter.vue";
import AuthenticatedLayoutHeader from "@/core/shared/components/Layout/AuthenticatedLayoutHeader.vue";

import { useRoute } from "vue-router";

defineOptions({
  name: "AuthenticatedLayout",
});

const route = useRoute();

const cssClasses = computed(() => {
  let cssClasses = ["authenticated-layout-content"];
  const routeCssClasses = route.meta.cssClasses as string[];
  if (routeCssClasses && routeCssClasses.length > 0) {
    cssClasses = [...cssClasses, ...routeCssClasses];
  }
  return cssClasses;
});
</script>

<style lang="scss" scoped>
.authenticated-layout {
  @apply flex lg:h-screen flex-col;

  &-content {
    @apply w-full h-full bg-gray-50 flex-1 lg:overflow-y-auto;

    &-white {
      @apply bg-white;
    }

    &-main {
      @apply min-h-[calc(100vh_-_426px)] sm:min-h-[calc(100vh_-_212px)];
    }
  }

  &-header {
    @apply relative z-30;
  }
}
</style>
