<template>
  <div class="notification-header">
    <span class="mr-1">{{ notification.title }}</span>
    <component
      :is="iconType.icon"
      v-if="notification.app_notification_style && !!iconType.icon"
      class="notification-header-icon"
      :class="iconType.color"
    />
    <time :class="['notification-header-date', { 'ml-1': !isSidebar }]">
      {{ createdOn }}
    </time>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DsAlertSolidIcon,
  DsCircleCheckSolidIcon,
  type DsLiveNotification,
  DsLiveToastStyleEnum,
  useDsDatetime,
} from "@devsalsa/vue-core";

import type { PropType } from "vue";

interface IconType {
  icon: string;
  color: string;
}
export default defineComponent({
  name: "NotificationHeader",
  components: {
    DsCircleCheckSolidIcon,
    DsAlertSolidIcon,
  },
  props: {
    notification: {
      type: Object as PropType<DsLiveNotification>,
      required: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    createdOn(): string {
      return this.notification.date_created
        ? useDsDatetime().formatUnixTime(this.notification.date_created)
        : "";
    },
    iconType(): IconType {
      if (
        this.notification.app_notification_style ===
        DsLiveToastStyleEnum.Success
      ) {
        return { icon: "DsCircleCheckSolidIcon", color: "text-green-400" };
      }
      if (
        this.notification.app_notification_style ===
        DsLiveToastStyleEnum.Critical
      ) {
        return { icon: "DsAlertSolidIcon", color: "text-red-500" };
      }
      return { icon: "", color: "" };
    },
  },
});
</script>

<style scoped lang="scss">
.notification-header {
  @apply font-medium text-sm text-gray-900 mb-1;

  &-date {
    @apply text-gray-500 whitespace-nowrap text-xs font-normal;
  }

  &-icon {
    @apply text-center inline-block align-sub mr-1 w-4 h-4;
  }
}
</style>
