import enCore from "@devsalsa/vue-core/dist/locales/en.json";

import type { IntlDateTimeFormats, IntlNumberFormats } from "vue-i18n";

import { MarketplaceCode } from "@/shared/enums/MarketplaceCodeEnum";

import deDe from "@/locales/de-DE.json";
import enAu from "@/locales/en-AU.json";
import enCa from "@/locales/en-CA.json";
import enGb from "@/locales/en-GB.json";
import enUs from "@/locales/en-US.json";

export function useMarketplace() {
  const datetimeFormats: IntlDateTimeFormats = {
    "en-US": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-GB": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-AU": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-CA": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-DE": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "de-DE": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
  };

  const numberFormats: IntlNumberFormats = {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-CA": {
      currency: {
        style: "currency",
        currency: "CAD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-GB": {
      currency: {
        style: "currency",
        currency: "GBP",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-AU": {
      currency: {
        style: "currency",
        currency: "AUD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-DE": {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "de-DE": {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
  };

  const messages = {
    "en-US": { ...enUs, ...enCore },
    "en-GB": { ...enGb, ...enCore },
    "en-CA": { ...enCa, ...enCore },
    "en-AU": { ...enAu, ...enCore },
    "en-DE": { ...enUs, ...enCore },
    "de-DE": { ...deDe, ...enCore },
  };

  function updateLocale(marketplace: string): string {
    if (marketplace === MarketplaceCode.Uk) {
      return "en-GB";
    }
    if (marketplace === MarketplaceCode.Ca) {
      return "en-CA";
    }
    if (marketplace === MarketplaceCode.Au) {
      return "en-AU";
    }
    if (marketplace === MarketplaceCode.De) {
      return "en-DE";
    }

    return "en-US";
  }

  function phoneMask(marketplace: string) {
    if (marketplace === MarketplaceCode.Au) {
      return { mask: "Z## ### ###", tokens: { Z: 4 } };
    }

    if (marketplace === MarketplaceCode.Uk) {
      return { mask: "Z### ### ###", tokens: { Z: 7 } };
    }

    if (marketplace === MarketplaceCode.De) {
      return { mask: "Z## #######", tokens: { Z: 1 } };
    }

    return { mask: "(###) ###-####", tokens: {} };
  }

  function phoneMaskPlaceholder(marketplace: string) {
    if (marketplace === MarketplaceCode.Au) {
      return "4## ### ###";
    }

    if (marketplace === MarketplaceCode.Uk) {
      return "7### ### ###";
    }

    if (marketplace === MarketplaceCode.De) {
      return "1## #######";
    }

    return "(###) ###-####";
  }

  const isTikTokShopMarketplace = (marketplace: string) =>
    marketplace === MarketplaceCode.Us || marketplace === MarketplaceCode.Uk;

  const isReimbursementTaxesMarketplace = (marketplace: string) =>
    marketplace === MarketplaceCode.Us || marketplace === MarketplaceCode.Ca;

  return {
    datetimeFormats,
    numberFormats,
    messages,
    updateLocale,
    phoneMask,
    phoneMaskPlaceholder,
    isTikTokShopMarketplace,
    isReimbursementTaxesMarketplace,
  };
}
