import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/marketplace",
    beforeEnter: OnboardingGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        name: "Marketplace",
        component: () =>
          import("@/modules/marketplace/views/TheMarketplace.vue"),
        meta: {
          title: "common.route.marketplace.meta",
          cssClasses: ["authenticated-layout-content-white"],
        },
      },
      {
        path: "express-jobs",
        name: "ExpressJobs",
        component: () =>
          import("@/modules/marketplace/views/TheExpressJobs.vue"),
        meta: { title: "common.route.marketplace.meta" },
      },
    ],
  },
];
