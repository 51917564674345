<template>
  <div class="authenticated-layout-header">
    <OffCanvas
      v-model:is-open="showMenu"
      :items="contextMenuItems"
      :items-account="accountMenuItems"
    />
    <NotificationsSidebar v-model:show="showNotifications" />
    <AuthenticatedNavbar
      v-model:off-canvas-value="showMenu"
      v-model:notification-value="showNotifications"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsModalHandler } from "@devsalsa/vue-core";

import AuthenticatedNavbar from "@/core/shared/components/Navigation/AuthenticatedNavbar.vue";
import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";
import OffCanvas from "@/core/shared/components/Navigation/OffCanvas.vue";

import NotificationsSidebar from "@/modules/notification/components/NotificationsSidebar.vue";

export default defineComponent({
  name: "AuthenticatedLayoutHeader",
  components: {
    AuthenticatedNavbar,
    OffCanvas,
    NotificationsSidebar,
  },
  data() {
    return {
      showMenu: false,
      showNotifications: false,
      contextMenuItems: [] as NavigationItem[],
      accountMenuItems: [] as NavigationItem[],
    };
  },
  created() {
    this.setContextMenu();
    this.setAccountMenuItems();
  },
  methods: {
    setContextMenu() {
      this.contextMenuItems = [
        {
          label: this.$t("core.shared.components.layout.navbar.dashboard"),
          route: {
            name: "Dashboard",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.marketplace"),
          route: {
            name: "Marketplace",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.jobs"),
          route: {
            name: "Jobs",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.recurringJobs"),
          route: {
            name: "TheRecurringJobs",
          },
        },
      ];
    },
    setAccountMenuItems() {
      this.accountMenuItems = [
        {
          label: this.$t("core.shared.components.layout.navbar.account"),
          route: {
            name: "Account",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.portfolio"),
          route: {
            name: "PortfolioSummary",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.reviews"),
          route: {
            name: "Reviews",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.wallet"),
          route: {
            name: "Wallet",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.payoutMethod"),
          route: {
            name: "PayoutMethod",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.certifications"),
          route: {
            name: "Certifications",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.notifications"),
          route: {
            name: "NotificationSettings",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.requestFeature"),
          route: {
            name: "RequestAFeature",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.contact"),
          route: {},
          action: () => this.onOpenModal(),
        },
        {
          label: this.$t("core.shared.components.layout.navbar.affiliate"),
          route: {
            name: "AffiliateProgram",
          },
        },
      ];
    },
    onOpenModal() {
      DsModalHandler.open("contact-support-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.authenticated-layout-header {
  @apply relative z-30;
}
</style>
