<template>
  <div class="creator-levels">
    <DsDatatable
      v-if="!populating"
      id="datatable-levels"
      :columns="datatable1.columns"
      :records="datatable1.records"
      :total-records="datatable1.records.length"
      data-testid="datatable-levels"
      :populating="populating"
      class="mb-8"
      :show-paginated="false"
    />
    <template v-else>
      <DsSkeleton class="h-12 w-full mb-1" />
      <DsSkeleton class="h-36 w-full" />
    </template>
    <DsDatatable
      v-if="!populating"
      id="datatable-levels-2"
      :columns="datatable2.columns"
      :records="datatable2.records"
      data-testid="datatable-levels-2"
      :total-records="datatable2.records.length"
      :populating="populating"
      :show-paginated="false"
    />
    <template v-else>
      <DsSkeleton class="h-12 w-full mb-1 mt-8" />
      <DsSkeleton class="h-36 w-full" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw } from "vue";

import {
  DsDatatable,
  type DsDatatableColumn,
  type DsDatatableRecord,
  DsFlashNotifier,
  DsSkeleton,
} from "@devsalsa/vue-core";

import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import TableStar from "@/modules/settings/components/account/TableStar.vue";

import AssetsService from "@/services/AssetsService";

import type { LevelRequirements } from "@/services/AssetsService.types";

interface Levels {
  [index: string]: number | string;
}

export default defineComponent({
  name: "CreatorLevels",
  components: {
    DsDatatable,
    DsSkeleton,
  },
  data() {
    return {
      datatable1: {
        columns: [
          {
            id: "title",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table1.head.title"
            ),
          },
          {
            id: "level2",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table1.head.level2"
            ),
          },
          {
            id: "level3",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table1.head.level3"
            ),
          },
        ] as DsDatatableColumn[],
        records: [] as DsDatatableRecord[],
      },
      datatable2: {
        columns: [
          {
            id: "title",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table2.head.title"
            ),
          },
          {
            id: "level2",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table2.head.level2"
            ),
          },
          {
            id: "level3",
            label: this.$t(
              "modules.settings.portfolio.creatorLevels.table2.head.level3"
            ),
          },
        ] as DsDatatableColumn[],
        records: [] as DsDatatableRecord[],
      },
      recordsPerPage: 10,
      levels: [] as LevelRequirements[],
      populating: false,
    };
  },
  async created() {
    await this.populateData();
  },
  methods: {
    async populateData() {
      this.populating = true;
      try {
        this.levels = await AssetsService.levelRequirements();
        const total = {} as Levels;
        const last = {} as Levels;
        const over = {} as Levels;
        const image = {} as Levels;
        const video = {} as Levels;
        const jobs = {} as Levels;
        const levelDone = [] as Levels[];
        const levelGive = [] as Levels[];

        total["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table1.rows.row1.title"
        );
        last["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table1.rows.row2.title"
        );
        over["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table1.rows.row3.title"
        );
        image["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table2.rows.row1.title"
        );
        video["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table2.rows.row2.title"
        );
        jobs["title"] = this.$t(
          "modules.settings.portfolio.creatorLevels.table2.rows.row3.title"
        );
        let idx = 2;
        for (const level of this.levels) {
          total[`level${idx}`] = level.total_completed_jobs;
          last[`level${idx}`] = level.completed_jobs_for_last_30_days;
          over[`level${idx}`] = level.maintain_rating_for_over_30_days;
          image[`level${idx}`] = level.image_earnings_percentage_increase;
          video[`level${idx}`] = level.video_earnings_percentage_increase;
          jobs[`level${idx}`] = level.nr_max_active_jobs;
          idx++;
        }
        levelDone.push(total, last);
        levelGive.push(image, video);

        for (const index in levelDone) {
          const row = levelDone[index];

          this.datatable1.records[index] = {
            id: parseInt(index) + 1,
            data: [
              {
                value: row.title,
              },
              {
                value: this.$t(
                  "modules.settings.portfolio.creatorLevels.table1.rows.row1.text2",
                  { count: row.level2 }
                ),
              },
              {
                value: this.$t(
                  "modules.settings.portfolio.creatorLevels.table1.rows.row1.text2",
                  { count: row.level3 }
                ),
              },
            ],
          };
        }

        const ratingOver = {
          id: this.datatable1.records.length + 1,
          data: [
            {
              value: over.title,
            },
            {
              value: over.level2,
              component: markRaw({
                name: TableStar,
                attributes: {
                  label: Number(over.level2).toFixed(1),
                },
              }),
            },
            {
              value: over.level3,
              component: markRaw({
                name: TableStar,
                attributes: {
                  label: Number(over.level3).toFixed(1),
                },
              }),
            },
          ],
        };
        this.datatable1.records.push(ratingOver);

        for (const index in levelGive) {
          const row = levelGive[index];

          this.datatable2.records[index] = {
            id: parseInt(index) + 1,
            data: [
              {
                value: row.title,
              },
              {
                value: this.$t(
                  "modules.settings.portfolio.creatorLevels.table2.rows.row1.text",
                  { total: row.level2 }
                ),
              },
              {
                value: this.$t(
                  "modules.settings.portfolio.creatorLevels.table2.rows.row1.text",
                  { total: row.level3 }
                ),
              },
            ],
          };
        }

        const maxJobs = {
          id: this.datatable2.records.length + 1,
          data: [
            {
              value: jobs.title,
            },
            {
              value: jobs.level2,
            },
            {
              value: jobs.level3,
            },
          ],
        };
        this.datatable2.records.push(maxJobs);

        this.populating = false;
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      }
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.ds-datatable-th-level2) > div,
:deep(.ds-datatable-th-level3) > div {
  @apply flex justify-end min-w-[50px];
}

:deep(.ds-datatable-td-level2) > div,
:deep(.ds-datatable-td-level3) > div {
  @apply flex justify-end;
}

:deep(.ds-datatable-td-title) > div {
  @apply min-w-[230px];
}
</style>
