import { ref } from "vue";

import type { DsIAuthService } from "@devsalsa/vue-core";

import AccountService from "@/core/modules/account/services/AccountService";
import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import { AuthTokenWatcher } from "@/core/shared/helpers/Auth/AuthTokenWatcher";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";

import * as Sentry from "@sentry/vue";
import type { PromisifyFn } from "@vueuse/core";
import { useDebounceFn } from "@vueuse/core";
import { container } from "tsyringe";

export default class AccountHandler {
  static accountInfo: { value: null | AccountInfo } = ref(null);

  /**
   *
   * @param update
   * @returns Object
   */
  static async getInfo(update = false) {
    if (!this.accountInfo.value || update) {
      this.accountInfo.value = await AccountService.getInfo();
      if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
        Sentry.setUser({ email: this.accountInfo.value.email });
      }
    }

    return this.accountInfo.value;
  }

  /**
   * @returns
   */
  static getDebounceInfo(): PromisifyFn<() => Promise<void>> {
    return useDebounceFn(async () => {
      await AccountHandler.getInfo(true);
    }, 3000);
  }

  /**
   * @param token
   * @returns Object
   */
  static async authenticate(token: string) {
    const authService = container.resolve<DsIAuthService>("AuthService");
    AuthTokenWatcher.stop();
    authService.setToken(token);
    AuthTokenWatcher.start();
    if (WebSocketService.isConnected()) {
      WebSocketService.authenticate();
    }
    return await AccountHandler.getInfo(true);
  }

  static isAuthenticated(): boolean {
    const authService = container.resolve<DsIAuthService>("AuthService");
    return authService.isAuthenticated();
  }

  static logout() {
    this.accountInfo.value = null;
    const authService = container.resolve<DsIAuthService>("AuthService");
    AuthTokenWatcher.stop();
    authService.logout();
    AuthTokenWatcher.start();
  }
}
